import * as React from 'react';

class StyleGuide extends React.Component<any, any> {
    render() {
        return (
            <div>
                <div className='header-1 fw-light'>Header 1 light</div>
                <div className='header-1 fw-boldest'>Header 1 boldest</div>
                <div className='header-2 fw-boldest'>Header 2 boldest</div>
                <div className='header-3 fw-extra-bold'>Header 3 extra bold</div>
                <div className='header-4 fw-semi-bold'>Header 4 semi bold</div>
                <div className='header-5 fw-bold'>Header 5 bold</div>
                <br />
                <div>--------------------------</div>
                <br />
                <div className="paragraph fw-light">Paragraph light</div>
                <div className='paragraph'>Paragraph regular</div>
                <div className='paragraph-small'>Paragraph small</div>
                <div className='paragraph-smallest'>Paragraph smallest</div>
                <br />
                <div>--------------------------</div>
                <br />
                <button className='btn-xxlarge btn-pink'>Btn xxlarge pink</button>
                <button className='btn-xxlarge btn-white'>Btn xxlarge white</button>

                <button className='btn-xlarge btn-pink'>Btn xlarge pink</button>
                <button className='btn-xlarge btn-white'>Btn xlarge white</button>

                <button className='btn-large btn-pink'>Btn large pink</button>
                <button className='btn-large btn-white'>Btn large white</button>

                <button className='btn-medium btn-pink'>Btn medium</button>
                <button className='btn-medium btn-white'>Btn white</button>

                <button className='btn-small btn-green'>BTN SMALL GREEN</button>
                <button className='btn-small btn-grey'>BTN SMALL GREY</button>

            </div>          
        );
    }
}

export default StyleGuide;