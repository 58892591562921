import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}
class ComplianceSecuritySection extends React.Component<any, State> {
    state = {
		elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {
		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({
				elementVisible: true,
			})
		}
	}
    render() {
        const { elementVisible } = this.state;
        return (
            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="security-section">
                <div className="securityCompllianceSec">
                    <div className="header-3 fw-extra-bold comHeader">Compliance & Security </div>
                    <div className="security-subHeading">you can trust</div>
                    <div className="security-desc paragraph">Managing, protecting and delivering the information critical to the productivity of our users and their various devices is essential. While our goal is to deliver a more efficient signing experience, compliance and security are at the forefront of our business practices.</div>
                </div>
                <div className="security-section-mainContainer">
                
                    <div className="security-sectionOver">
                        <div className="security-text-background-container">
                            <Transition visible={elementVisible} animation="fade up" duration={2500}>
                                <div className="security-text-contentLeft bottomSecLeft">
                                    <div className="security-complianceIcon"></div>
                                    <div className="security-complianceText">Compliance</div>
                                    <div className="paragraph fw-light txt-inner">FIN Title is dedicated to guiding you through the complex systems that govern regulatory compliance. We operate under a rigorous security policy to insure the integrity of the data in our care. We make sure our standards for compliance are in line with regulatory requirements and industry best practices</div>

                                </div>
                            </Transition>
                        </div>
                        <div className="security-text-background-container">
                            <Transition visible={elementVisible} animation="fade up" duration={2500}>
                                <div className="security-text-contentRight imgSec">
                                <div className="security-securityIcon"></div>
                                <div className="security-securityText">Security</div>
                                    <div className="paragraph fw-light txt-inner">FIN Title has the highest standards for safety and security of your data. We are committed to providing peace-of mind and maintaining the confidentiality, integrity and security of any personal information shared with us.</div>
                                </div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </Visibility>
        );
    }
}

export default ComplianceSecuritySection;