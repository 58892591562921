import * as React from 'react';
import MapContainer from './mapContainer';
import { Office, headquarters, dallas, coraopolis } from "../../data/offices";

interface State {
    activeOffice: Office;
}

class HeadquartersContactSection extends React.Component<{}, State> {

    state: State = {
        activeOffice: headquarters
    }

    render() {
        const { activeOffice } = this.state;
        return (
            <div className="headQuaters-container">
                <div className="headQuatersMain">
                    <div className={`headQuatersMainLeft background-map_${activeOffice.name.toLowerCase()}`}>
                        <div className="headQuaters-textSec">
                            <div className="header-3 fw-extra-bold header">We’re here</div>
                            <div className="paragraph fw-light des">Come on over.</div>
                            <div className="offices-tabs">
                                <div className={`header-5 office-tab ${activeOffice === headquarters ? "active" : ""}`} onClick={() => this.setState({activeOffice: headquarters})}>{headquarters.name.toUpperCase()}</div>
                                <div className={`header-5 office-tab office-tab_margin ${activeOffice === dallas ? "active" : ""}`} onClick={() => this.setState({activeOffice: dallas})}>{dallas.name.toUpperCase()}</div>
                                <div className={`header-5 office-tab ${activeOffice === coraopolis ? "active" : ""}`} onClick={() => this.setState({activeOffice: coraopolis})}>{coraopolis.name.toUpperCase()}</div>
                            </div>
                            <div className="header-5 fw-bold head">{activeOffice.name}</div>
                            <div className="paragraph fw-light para">{activeOffice.address1}</div>
                            <div className="paragraph fw-light para">{activeOffice.address2}</div>
                            <div className="headQuaters-buttonSec">
                                <div className="WorkTogether-Sec">
                                    <a
                                        className="btn-xlarge btn-pink contact"
                                        href={activeOffice.directionsUrl}
                                        target="_blank"
                                    >
                                        <div className="btn-Contact">Get Directions</div> <span className="icon_right"></span>
                                    </a>
                                </div>
                                <a
                                    href={activeOffice.mapUrl}
                                    target="_blank"
                                    className="btn-xlarge btn-white contact"
                                >
                                    Open in Google Maps
                                </a>
                            </div>

                        </div>
                    </div>
                    <div className="headQuatersMainRightMap">
                        <MapContainer activeOffice={activeOffice} />
                    </div>
                    <div className="headQuatersMainRight">
                        <div className="headQuatersTalk">
                            <div className="header-3 fw-extra-bold head">Let’s talk</div>
                            <div className="paragraph fw-light subHead">How can we help?</div>
                        </div>
                        <div className="headQuatersEnquiries">
                            <div className="headQuatersGeneral">
                                <div className="header-5 fw-bold enquiries">General Inquiries</div>
                                <div>
                                    <a href="tel:1-888-346-8485" className="paragraph fw-light number">(888) FIN-TITLE</a>
                                </div>
                                <div>
                                    <a href="tel:1-888-346-8485" className="paragraph fw-light number">(888) 346-8485</a>
                                </div>
                                <div>
                                    <a href="mailto:info@fintitle.com" className="paragraph fw-light number">info@fintitle.com</a>
                                </div>
                            </div>
                            <div className="headQuatersSupport">
                                <div className="header-5 fw-bold enquiries">Support</div>
                                <div>
                                    <a href="tel:1-888-346-8485" className="paragraph fw-light number">(888) 346-8485</a>
                                </div>
                                <div>
                                    <a href="mailto:help@fintitle.com" className="paragraph fw-light number">help@fintitle.com</a>
                                </div>
                            </div>
                        </div>
                        <div className="headQuatersSales">
                            <div className="headQuatersSale">
                                <div className="header-5 fw-bold enquiries">Sales</div>
                                <div>
                                    <a href="tel:1-888-346-8485" className="paragraph fw-light number">(888) 346-8485</a>
                                </div>
                                <div>
                                    <a href="mailto:sales@fintitle.com" className="paragraph fw-light number">sales@fintitle.com</a>
                                </div>
                                <div className="headQuaters-funTxt">#workwithfin</div>
                            </div>
                            <div className="headQuatersCareers">
                                <div className="header-5 fw-bold careers">Careers</div>
                                <a href="mailto:jobs@fintitle.com" className="paragraph fw-light number">jobs@fintitle.com</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default HeadquartersContactSection;