import * as React from 'react';
import HeroSection from './heroSection';
import WhyUsSection from './whyUsSection';
import MissionStatementSection from './missionStatementSection';
import ValueSection from './valuesSection';
import TeamMembersSection from './teamMemberSection';
import NewsSection from './newsSection';
import JobsContainer from './jobs/jobsContainer';
import Footer from '../footer';
import { NavLink } from 'react-router-dom';

interface Props {
    handleHiringLinkClick: () => void;
}

class AboutPageContainer extends React.Component<Props, any> {
    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="aboutPage-Container">
                <HeroSection />
                <WhyUsSection />
                <MissionStatementSection />
                <div className="paragraph-linkSec">
                    <NavLink to="/technology" className="paragraph-end-link" onClick={this.scrollToTop}>Learn about FIN technology</NavLink>
                </div>
                <ValueSection />
                <NewsSection />
                {/* <JobsContainer /> */}
                <Footer />
            </div>
        );
    }
}

export default AboutPageContainer;