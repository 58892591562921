import * as React from 'react';
import NavBar from '../navBar';

class HeroSection extends React.Component<any, any> {
    render() {
        return (
            <React.Fragment>
                <div className="navbar-hero-container">
                    <div className="contact-hero-container">
                        <NavBar />
                        <div className="hero-content">
                            <div className="hero-content-container">
                                <div className="hero-text-content">
                                    <div className="hero-text-contentSec">
                                        <div className="hero-text-contentSecLeftWeb">
                                            <div className="header-4 hero-title">Contact Us</div>
                                            <div className="hero-title-transaction">How can we</div>
                                            <div className="hero-paragraph">help today?</div>

                                        </div>
                                        <div className="contact-heroPlusSign"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-text-contentSecLeftMob">
                    <div className="header-4 hero-title">Contact Us</div>
                    <div className="hero-title-transaction">How can we</div>
                    <div className="hero-paragraph">help today?</div>
                </div>
            </React.Fragment>
        );
    }
}

export default HeroSection;