import * as React from 'react';
import NavBar from '../navBar';
import ServicesDescription from './ServicesDescriptionSection';
import { Dropdown, Grid, Segment } from 'semantic-ui-react';

const serviceOptions = [
    { key: '1', value: 'Default Services', text: 'Default Services' },
    { key: '2', value: 'Origination Services', text: 'Origination Services' },
    { key: '3', value: 'Retail Services', text: 'Retail Services' }

]

const customerOptions = [
    { key: '1', value: 'Financial Pro', text: 'Financial Pro' },
    { key: '2', value: 'Real Estate Pro', text: 'Real Estate Pro' },
    { key: '3', value: 'Servicer', text: 'Servicer' },
    { key: '4', value: 'Consumer', text: 'Consumer' }

]

interface State {
    [key: string]: string | boolean;
    value: string;
}


class HeroSection extends React.Component<any, any> {
    state = {
        value: '',
        val:'Select service type',
        place:'Select customer type'
    }
    handleChange = (e: any, { value}: any) => {
        this.setState({
            value: value
        })
    }
    handleChangeRight = (e: any, { value }: any) => {
        this.setState({
            value: value
        })
    }

    render() {
        const { value, val, place} = this.state;

        return (
            <div>
                <div className="navbar-hero-container">
                    <div className="service-hero-container">
                        <NavBar />
                        <div className="hero-contentWeb">
                            <div className="hero-content-container">
                                <div className="hero-text-content">
                                    <div className="hero-text-contentSec">
                                        <div className="hero-text-contentSecLeft">
                                            <div className="header-4 hero-title">Services</div>
                                            <div className="header-1 fw-boldest txt">Title and escrow services</div>
                                            <div className="header-1 fw-boldest subTxt">for all your real estate </div>
                                            <div className="header-1 fw-boldest subTxt"> needs.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="service-offeringContainerWeb">
                            <div className="service-offerSection">
                                <div className="header-5 fw-bold textService">View our service offerings by selecting an option</div>
                                <div className="service-offeringBtnSection">
                                    <div className="service-offeringMenu">
                                        <Dropdown id="left"
                                            onChange={this.handleChange}
                                            options={serviceOptions}
                                            placeholder={val}
                                            selectOnBlur={false}
                                            selection
                                            value={value}
                                        />
                                    </div>
                                    <Dropdown id="right"
                                        onChange={this.handleChangeRight}
                                        options={customerOptions}
                                        placeholder={place}
                                        selection
                                        selectOnBlur={false}
                                        value={value}
                                    />
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
                <div className="hero-contentMob">
                    <div className="hero-content-container">
                        <div className="hero-text-content">
                            <div className="hero-text-contentSec">
                                <div className="hero-text-contentSecLeft">
                                    <div className="header-4 hero-title">Services</div>
                                    <div className="header-1 fw-boldest txt">Title and escrow services</div>
                                    <div className="header-1 fw-boldest subTxt">for all your real estate needs. </div>
                                    <div className="header-1 fw-boldest subTxt"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="service-offeringContainerMob">
                    <div className="service-offerSection">
                        <div className="header-5 fw-bold textService">View our service offerings by selecting an option</div>
                        <div className="service-offeringBtnSection">
                            <div className="service-offeringMenu">
                                <Dropdown
                                    onChange={this.handleChange}
                                    options={serviceOptions}
                                    placeholder={val}
                                    selectOnBlur={false}
                                    selection
                                    value={value}
                                />
                            </div>
                            <div className="serviceBtn-seperator">or</div>
                            <Dropdown
                                onChange={this.handleChangeRight}
                                options={customerOptions}
                                placeholder={place}
                                selection
                                selectOnBlur={false}
                                value={value}
                            />
                        </div>
                    </div>
                </div> */}
                <ServicesDescription value={this.state.value} />
            </div>
        );
    }
}

export default HeroSection;