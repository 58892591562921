import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';


class IntegrationsSection extends React.Component<any, any> {
    scrollToTop = () => { 
		window.scrollTo(0, 0); 
	}
    render() {
        return (
            <div className="integration-section">
                <div className="integration-section-mainContainer">
                    <div className="integration-sectionOver">
                        <div className="integration-integrations">
                            <div className="header-3 fw-extra-bold mainTxt">Integrations</div>
                            <div className="integration-desc">FIN Title offers the ability to integrate with a third-party LOS or proprietary systems from incoming referrals to the delivery of the full product, product specific data and invoicing.</div>
                            <div className="integration-desc">Let’s chat about your system, and the data and documentation you need.</div>
                        </div>                       
                        <NavLink to="/contact" className="integration-contactUs" onClick={this.scrollToTop}>
                            <div className="WorkTogether-Sec">
                                <button className="btn-xlarge btn-pink contact">
                                    <div className="btn-Contact">Contact Us</div><span className="icon_right"></span>
                                </button>
                            </div>
                        </NavLink>

                    </div>
                </div>
            </div>
        );
    }
}

export default IntegrationsSection;