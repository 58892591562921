import React from "react";
import ReactDOMServer from "react-dom/server";
import {ContactInfo} from "./sendNoteForm";

export function contactEmailBody(contact: ContactInfo) {
  const body = ReactDOMServer.renderToStaticMarkup(
    <div>
      <h1>Contact Us Request</h1>
      <p>
        The following submission has been received from the Contact page on
        finstitle.com:
      </p>
      <br></br>
      <p>
        Topic: <span>{contact.topic}</span>
      </p>
      <p>
        Name:{" "}
        <span>
          {contact.name}
        </span>
      </p>
      <p>
        Email: <span>{contact.email}</span>
      </p>
      <p>
        Phone: <span>{contact.phone}</span>
      </p>
      <p>
        Message: <span>{contact.message}</span>
      </p>
    </div>
  );

  return body;
}