import * as React from 'react';
import { offices } from '../../data/offices';

class OfficesSection extends React.Component {
    render() {
        return (
            <div className="news-sectionContainer">
                <div className="news-textSection">
                    <div className="header-3 fw-extra-bold newsHeader">Locations</div>
                    <div className="offices-grid">
                        {offices.map(o => (
                            <article key={`office-${o.id}`}>
                                <div className="header-5 fw-bold office-name">{o.name}</div>
                                <div className="paragraph">{o.address1}</div>
                                <div className="paragraph">{o.address2}</div>
                                <div className="office-card-links">
                                    <a className="office-card-link office-card-link_margin" target="_blank" href={o.mapUrl}><u>Map</u></a>
                                    <a className="office-card-link" target="_blank" href={o.directionsUrl}><u>Directions</u></a>
                                </div>
                            </article>
                        ))}
                    </div>                    
                </div>
            </div>
        );
    }
}

export default OfficesSection;