import * as React from 'react';
import Footer from '../footer';
import HeroSection from './heroSection';
import PlatformSection from './platformSection';
import FarmingSection from './farmingSection';
import FeaturesSection from './featuresSection';
import IntegrationSection from './integrationsSection';
import ComplianceSecuritySection from './complianceSecuritySection';

class TechPageContainer extends React.Component<any, any> {
    render() {
        return (
            <div className="techPage-Container">
                <HeroSection />
                <div className="hero-contentMob">
                    <div className="hero-content-container">
                        <div className="hero-text-content">
                            <div className="hero-text-contentSec">
                                <div className="hero-text-contentSecLeft">
                                    <div className="header-4 hero-title" >Technology</div>
                                    <div className="hero-title-transaction">Behind each transaction</div>
                                    <div className="hero-paragraph">
                                        is a system designed for efficiency.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PlatformSection />
                {/* <FarmingSection /> */}
                {/* <FeaturesSection /> */}
                <IntegrationSection />
                <ComplianceSecuritySection />
                <Footer />
            </div>
        );
    }
}

export default TechPageContainer;