import * as React from 'react';

class MissionStatementSection extends React.Component<any, any> {
    render() {
        return (
            <div className="mission-sectionContainer">
                <div className="mission-plusIcon">
                    <div className="mission-statement">
                        <div className="header-3 fw-extra-bold missionTxtDes">By focusing on technology and customer experience, FIN will transform the traditional real estate closing experience.</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MissionStatementSection;