
export const scrollWithOffset = (el: HTMLElement, offset: number) => {

    const elementPosition = el.offsetTop - offset;

    window.scrollTo({
        top: elementPosition,
        left: 0,
        behavior: 'smooth'
    });

}