import * as React from 'react';
import NavBar from '../navBar';


class 
HeroSection extends React.Component<any, any> {
    render() {
        return (
            <div className="navbar-hero-container">
            <div className="aboutPage-hero-container">
                <NavBar />
                <div className="hero-content">
                    <div className="hero-content-container">
                        <div className="hero-text-content">
                            <div className="hero-text-contentSec">
                                <div className="hero-text-contentSecLeft">
                                    <div className="header-4 hero-title" >About Us</div>
                                    <div className="hero-title-transaction">Customer focused,</div>
                                    <div className="hero-paragraph"> technology-driven.</div>
                                    <div className="about-heroPlusSign"></div>
                                    <div className="about-FinTechDesc">FIN is a tech-forward title insurance and real estate closing company focused on a logical blend of technology and high-touch that delivers a truly enjoyable customer experience.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="about-heroBottomSec"></div> */}
            </div>
        </div>
        );
    }
}

export default HeroSection;