import * as React from 'react';
import HeroSection from './heroSection';
import Footer from '../footer';
import SendNoteForm from './sendNoteForm';
import HeadquatersContact from './headquartersContactSection';
import OtherLocationsSection from './otherLocationsSection';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../../utilities/scrollWithOffset';

interface Props {
    hiringLinkClicked: boolean;
    resetHiringLink: () => void;
}

class ContactPageContainer extends React.Component<Props, any> {

    scrollToTop = () => {
        window.scroll(0,0);
    }    

    render() {

        const { hiringLinkClicked, resetHiringLink } = this.props;

        return (
            <div className="contact-Container">
                <HeroSection />
                <SendNoteForm hiringLinkClicked={hiringLinkClicked} resetHiringLink={resetHiringLink} />
                <HeadquatersContact />
                <OtherLocationsSection />
                <div className="aboutHiring-container">
                    <div className="aboutHiring-content">
                        <div className="aboutHiring-Title">We’re Hiring</div>
                        <div className="aboutHiring-desc">Drop us a note using the form above or by sending us an email.  We’d love to talk to you.</div>
                        <div className="aboutHiring-ContactSec">We’re always looking for qualified and passionate individuals to join our team.</div>
                        <div className="WorkTogether-ContactSec">
                            <HashLink to='/about#jobs' scroll={(el: HTMLElement) => scrollWithOffset(el, 100)}>
                                <div className="btn-xlarge btn-white">View Jobs</div>
                            </HashLink>
                            <span className="WorkTogether-seperator">or</span>
                            <NavLink to="/contact" className="WorkTogether-Sec" onClick={this.scrollToTop}>
                                <button className="btn-xlarge btn-pink contact">
                                    <div className="btn-Contact">Contact Us</div>
                                    <span className="icon_right"></span>
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ContactPageContainer;