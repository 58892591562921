import { uniqueId } from "../utilities/uniqueId";

interface LatLng {
    lat: number;
    lng: number;
}

export interface Office {
    id: string;
    name: string;
    address1: string;
    address2: string;
    directionsUrl: string;
    mapUrl: string;
    position: LatLng;
}

export const headquarters: Office = {
    id: uniqueId(),
    name: "Headquarters",
    address1: "18201 Von Karman, Suite 330",
    address2: "Irvine, CA 92612",
    directionsUrl: "https://www.google.com/maps/dir//18201+Von+Karman+Ave+%23330,+Irvine,+CA+92612",
    mapUrl: "https://www.google.com/maps/place/18201+Von+Karman+Ave+%23330,+Irvine,+CA+92612",
    position: { lat: 33.678141, lng: -117.853590}
};

export const dallas: Office = {
    id: uniqueId(),
    name: "Addison",
    address1: "15455 Dallas Parkway, Suite 600",
    address2: "Addison, TX 75001",
    directionsUrl: "https://www.google.com/maps/dir//15455+Dallas+Parkway+%23600,+Addison,+TX+75001",
    mapUrl: "https://www.google.com/maps/place/15455+Dallas+Parkway+%23600,+Addison,+TX+75001",
    position: { lat: 32.960065, lng: -96.821993 }
};

export const coraopolis: Office = {
    id: uniqueId(),
    name: "Coraopolis",
    address1: "345 Rouser Road, Suite 401",
    address2: "Coraopolis, PA 15108",
    directionsUrl: "https://www.google.com/maps/dir//345+Rouser+Rd,+Coraopolis,+PA+15108",
    mapUrl: "https://www.google.com/maps/place/345+Rouser+Rd,+Coraopolis,+PA+15108",
    position: { lat: 40.50202061377843, lng: -80.211273159163 }
};

export const offices: Office[] = [
    headquarters,
    dallas,
    coraopolis
];