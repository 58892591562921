import * as React from 'react';
import NavBar from '../navBar';

class HeroSection extends React.Component<any, any> {
    render() {
        return (
            <div className="navbar-hero-container">
                <div className="techPage-hero-container">
                    <NavBar />
                    <div className="hero-contentWeb">
                        <div className="hero-content-container">
                            <div className="hero-text-content">
                                <div className="hero-text-contentSec">
                                    <div className="hero-text-contentSecLeft">
                                        <div className="header-4 hero-title" >Technology</div>
                                        <div className="hero-title-transaction">Behind each transaction</div>
                                        <div className="hero-paragraph">
                                            is a system designed for efficiency.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HeroSection;