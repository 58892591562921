import * as React from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../utilities/scrollWithOffset';


class Footer extends React.Component<any, any> {
	scrollToTop = () => {
		window.scrollTo(0, 0);
	}
	getCurrentYear = () => {
		let dt = new Date();
		let year = dt.getFullYear();
		return year;
	}

	render() {
		return (
			<div className="footer-container">
				<div className="footer-content-container">
					<div className="footer-contentMain-container">
						<div className="footer-text-background-container">
							<div className="footer-text-content">
								<div className="footer-text-contentLeft">
									<div className="footer-Fin-icon"></div>
									<div className="footer-Fin-Text">Delivering the title insurance of tomorrow, today.</div>
									<div className="footer-inputText">
										<input className="footer-placeHolder" placeholder="Submit your email"></input>
										<div className="footer-emailSent">
											<div className="footer-emailSentIcon"></div>
										</div>
									</div>
									<div className="footer-allRightsWeb">© {this.getCurrentYear()} First Integrity Title National Solutions, Llc.  &nbsp; All Rights Reserved. <NavLink to="privacy" className="footer-textPolicy Privacy">Privacy Policy</NavLink> <NavLink to="terms" className="footer-textPolicy">Terms of Service</NavLink></div>

								</div>
								<div className="footer-page-section">
									<div className="footer-section">
										<div className="footer-section-left">
											<div>
												<NavLink to="/" className="footer-pageNav" onClick={this.scrollToTop}>Home</NavLink>
											</div>
											<div>
												<NavLink to="/services" className="footer-pageNav" onClick={this.scrollToTop}>Services</NavLink>
											</div>
											<div>
												<NavLink to="/technology" className="footer-pageNav" onClick={this.scrollToTop}>Technology</NavLink>
											</div>
											<div>
												<NavLink to="/about" className="footer-pageNav" onClick={this.scrollToTop}>About</NavLink>
											</div>
											<HashLink to='/about#jobs' scroll={(el: HTMLElement) => scrollWithOffset(el, 100)}>
												<div className='footer-pageNav'>Careers</div>
											</HashLink>
											<div>
												<NavLink to="/contact" className="footer-pageNav" onClick={this.scrollToTop}>Contact</NavLink>
											</div>
										</div>
										<div className="footer-section-right">
											<div className="footer-pageNav">18201 Von Karman, Ste. 330</div>
											<div className="footer-pageNav">Irvine, CA 92612</div>
											<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<a href="tel:1-888-346-8485" className="footer-pageNav">(888) FIN-TITLE</a>
											</div>
											<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<a href="mailto:info@fintitle.com" className="footer-pageNav">info@fintitle.com</a>
											</div>
											<div className="footer-pageNav socialLogos">
												<div className="footer-instagramLogo insta"></div>
												<a target="_blank" href="https://www.facebook.com/fintitle/" className="footer-instagramLogo fbLogo"></a>
												<a target="_blank" href="https://www.linkedin.com/company/fintitle/" className="footer-instagramLogo twitterLogo"></a>
											</div>

										</div>
									</div>
								</div>
								<Link to="navbar-hero-container" offset={0} smooth={true} duration={1000} className="footer-bottomToTop">
									<div className="footer-bottomToTopIcon"></div>
								</Link>
								<div className="footer-allRightsMob">© {this.getCurrentYear()} First Integrity Title National Solutions, Llc.  &nbsp; All Rights Reserved.</div>
								<div className="footer_rights">
									<NavLink to="privacy" className="footer-textPolicy Privacy">Privacy Policy</NavLink>
									<NavLink to="terms" className="footer-textPolicy">Terms of Service</NavLink>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default Footer;