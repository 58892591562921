import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
    elementVisible: boolean;
}

class OtherLocationsSection extends React.Component<any, State> {
    state = {
        elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {
        const { elementVisible } = this.state;
        const { topVisible, bottomVisible } = data.calculations;

        if (!elementVisible && (topVisible || bottomVisible)) {
            this.setState({
                elementVisible: true,
            })
        }
    }
    render() {
        const { elementVisible } = this.state;
        return (
            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="otherLocation-container">
                <div className="otherLocation-main">
                    <div className="header-3 fw-extra-bold text">And we’re here, also</div>
                    <div className="otherLocation-InsideMain">
                        <div className="otherLocation-leftSec">
                            <Transition visible={elementVisible} animation="fade right" duration={2500} >
                                <div className="otherLocationText">
                                    <div className="otherLocationTexts">
                                        <div className="otherTexts">
                                            <div className="header-5 fw-bold address">Laguna Hills, CA</div>
                                            <div className="paragraph fw-light subAddress">1000 Some Address St., Suite 101</div>
                                            <div className="paragraph fw-light number">Laguna Hills, CA 99999</div>
                                            <div className="paragraph fw-light number">Phone: (555) 555-5555</div>
                                            <div className="headQuaters-buttonSec">
                                                <div className="WorkTogether-Sec">
                                                    <button className="btn-xlarge btn-pink contact">
                                                        <div className="btn-Contact">Get Directions</div> <span className="icon_right"></span>
                                                    </button>
                                                </div>
                                                <button className="btn-xlarge btn-white contact">Open in Google Maps</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                            <div className="otherLocation-mapLaguna" id='google-map-lagunaHills' ></div>

                        </div>

                    </div>
                    <div className="otherLocation-rightSec">
                        <Transition visible={elementVisible} animation="fade left" duration={2500}>
                            <div className="otherLocationTextMap">
                            <div className="otherLocationTexts">
                            <div className="otherTexts">
                                <div className="header-5 fw-bold address">San Diego, CA</div>
                                <div className="paragraph fw-light subAddress">2000 E Some Address St., Suite 2</div>
                                <div className="paragraph fw-light number">San Diego, CA 99999</div>
                                <div className="paragraph fw-light number">Phone: (555) 555-5555</div>
                                <div className="headQuaters-buttonSec">
                                    <div className="WorkTogether-Sec">
                                        <button className="btn-xlarge btn-pink contact">
                                            <div className="btn-Contact">Get Directions</div> <span className="icon_right"></span>
                                        </button>
                                    </div>
                                    <button className="btn-xlarge btn-white contact">Open in Google Maps</button>
                                </div>
                            </div>
                            </div>
                            </div>
                        </Transition>
                        <div className="otherLocation-mapLaguna" id='google-map-sanDiego' ></div>
                    </div>
                    <div className="contactSeperator">
                        <div className="contact-plusSignSeparator"></div>
                    </div>
                </div>
            </Visibility>
        );
    }
}

export default OtherLocationsSection;