import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
	elementVisible: boolean;
}

class CustomerExperienceSection extends React.Component<any, any> {
    state = {
		elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {
		const { elementVisible } = this.state;
		const { topVisible, bottomVisible } = data.calculations;

		if (!elementVisible && (topVisible || bottomVisible)) {
			this.setState({
				elementVisible: true,
			})
		}
	}
    render() {
        const { elementVisible } = this.state;
        return (
            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="customer-serviceContainer">
                <div className="customer-experience-container">
					<div className="customer-content-container">
						
						<Transition visible={elementVisible} animation="fade right" duration={2500}>						
							<div className="customer-transition-container">
								<div className="customer-container-column-left">
									<div className="customer-img-container" />
								</div>
							</div>
						</Transition>
                        <div className="customer-text-background-container">
                        <div className="customer-text-content">
                                <div className="customer-header-container" >
                                    <div className="customer-lighter-header">Customer</div>
                                    <div className="customer-brighter-header">Experience</div>
                                </div>			
								<div className="customer-text-paragraphs">
                                    First Integrity Title offers a centralized experienced team for the products and services selected, and efficient and reliable service.  From a single property report to multiple properties or complex properties, FIN Title provides a dedicated team from referral to product delivery.  Our goal is to synergize the efficiency and level of service you are looking for.									
								</div>								
							</div>						
						</div>
					</div>
				</div>
            </Visibility>
        );
    }
    
}

export default CustomerExperienceSection;



