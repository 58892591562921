import * as React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { emailValid, phoneValid } from '../../utilities/formFieldValidator';
import { callEmailHandler } from "../../utilities/callEmailHandler";
import { contactEmailBody } from "./utils";

interface Response {
    complete: boolean;
    ok: boolean;
}

export interface ContactInfo {
    name: string;
    topic: string;
    phone: string;
    email: string;
    message: string;
}

interface State {
    contactInfo: ContactInfo;
    response: Response;
    btnDisabled: boolean;
    nameValid: boolean;
    topicValid: boolean;
    phoneValid: boolean;
    emailValid: boolean;
    messageValid: boolean;
}

const reasonOptions = [
    { key: '1', value: "I'd like to work together", text: "I'd like to work together" },
    { key: '2', value: "I’m interested in working at FIN", text: "I’m interested in working at FIN" },
    { key: '3', value: 'General question or comment', text: 'General question or comment' },
    { key: '4', value: 'Other', text: 'Other' }
];

interface Props {
    hiringLinkClicked: boolean;
    resetHiringLink: () => void;
}

class SendNoteForm extends React.Component<Props, State> {

    state = {
        contactInfo: {
            name: '',
            topic: this.props.hiringLinkClicked ? "I’m interested in working at FIN" : '',
            phone: '',
            email: '',
            message: '',
        },
        response: { complete: false, ok: false },
        btnDisabled: false,
        nameValid: true,
        topicValid: true,
        phoneValid: true,
        emailValid: true,
        messageValid: true
    }    

    componentDidUpdate(prevProps: any, prevState: State) {

        const { response } = this.state;

        if (!prevState.response.complete && response.complete) {
            setTimeout(this.resetResponse, 5000);
        }
    }

    componentWillUnmount() {
        const { hiringLinkClicked, resetHiringLink } = this.props;
        if (hiringLinkClicked) resetHiringLink();
    }

    handleInputChange = (e: any) => {

        switch (e.target.name) {
            case 'name':
                this.setState({
                    ...this.state,
                    contactInfo: {
                        ...this.state.contactInfo,
                        name: e.target.value
                    },
                    nameValid: true
                });
                break;
            case 'phone':
                this.setState({
                    ...this.state,
                    contactInfo: {
                        ...this.state.contactInfo,
                        phone: e.target.value
                    },
                    phoneValid: true
                });
                break;
            case 'email':
                this.setState({
                    ...this.state,
                    contactInfo: {
                        ...this.state.contactInfo,
                        email: e.target.value
                    },
                    emailValid: true
                });
                break;
            case 'message':
                this.setState({
                    ...this.state,
                    contactInfo: {
                        ...this.state.contactInfo,
                        message: e.target.value
                    },
                    messageValid: true
                });
                break;
        }
    }

    handleDropdownChange = (e: any, { value }: any) => {
        this.setState({
            ...this.state,
            contactInfo: {
                ...this.state.contactInfo,
                topic: value
            },
            topicValid: true
        });
    }

    handleSubmit = () => {

        const { btnDisabled } = this.state;

        // submit the request if the btn is enabled and required fields are valid
        if (!btnDisabled && this.validateForm()) {            
            this.setState({
                btnDisabled: true
            }, () => {
                this.sendRequest();
            });
        }
    }
    
    sendRequest = () => {
        callEmailHandler({
            to: ["info@fintitle.com"],
            subject: "The following submission has been received from the Contact Us page on FinTitle.com:",
            text: contactEmailBody(this.state.contactInfo)
        })
            .then((resp) => {
                if (resp.ok) {
                    this.setState({
                        response: { complete: true, ok: true },
                        contactInfo: {
                            name: '', topic: '', phone: '', email: '', message: ''
                        },
                        btnDisabled: false
                    });
                } else {
                    this.setState({
                        response: { complete: true, ok: false },
                        contactInfo: {
                            name: '', topic: '', phone: '', email: '', message: ''
                        },
                        btnDisabled: false
                    });
                }
            })
            .catch(() => {
                this.setState({
                    response: { complete: true, ok: false },
                    contactInfo: {
                        name: '', topic: '', phone: '', email: '', message: ''
                    },
                    btnDisabled: false
                });
            });
    }

    renderForm = () => {

        const { contactInfo, response, btnDisabled, nameValid, topicValid, emailValid, phoneValid, messageValid } = this.state;

        return (
            <div className="contact-inputNoteSec" style={response.complete ? { visibility: 'hidden' } : {}}>
                <div className="contact-inputNoteSecLeft">
                    <input
                        className="name-input"
                        type="text"
                        placeholder="Name"
                        value={contactInfo.name}
                        name='name'
                        onChange={this.handleInputChange}
                        style={!nameValid ? {borderColor: 'red'} : {}}
                    />
                    <Dropdown className="name-input dropdown"
                        onChange={this.handleDropdownChange}
                        options={reasonOptions}
                        placeholder='Reason for reaching out?'
                        selectOnBlur={false}
                        selection
                        value={contactInfo.topic}
                        style={!topicValid? { borderColor: 'red' } : {}}
                    />
                    <input
                        className="name-input"
                        placeholder="Email address"
                        value={contactInfo.email}
                        name='email'
                        onChange={this.handleInputChange}
                        style={!emailValid ? { borderColor: 'red' } : {}}
                    />
                    <input
                        className="name-input"
                        placeholder="Phone (Optional)"
                        value={contactInfo.phone}
                        name='phone'
                        onChange={this.handleInputChange}
                        style={!phoneValid ? { borderColor: 'red' } : {}}
                    />
                </div>
                <div className="contact-inputNoteSecRight">
                    <textarea
                        className="contact-TextArea"
                        placeholder="Tell us a little more…"
                        value={contactInfo.message}
                        name='message'
                        onChange={this.handleInputChange}
                        style={!messageValid ? { borderColor: 'red' } : {}}
                    />
                    <div className="aboutHiring-Sec">
                        <button className="btn-xlarge btn-pink contact" disabled={btnDisabled} style={btnDisabled ? { backgroundColor: '#efefef' } : {}} onClick={this.handleSubmit}>
                            <div className="btn-Contact">Submit</div>
                            <span className="icon_right"></span>
                        </button>
                    </div>
                </div>

            </div>  
        );
    }

    validateForm = () => {

        const { contactInfo } = this.state;

        let fieldsValid = true;

        if (!contactInfo.name) {
            this.setState({ nameValid: false });
            fieldsValid = false;
        } 
        if (!contactInfo.topic) {
            this.setState({ topicValid: false });
            fieldsValid = false;
        }
        if (!contactInfo.email || !emailValid(contactInfo.email)) {
            this.setState({ emailValid: false });
            fieldsValid = false;
        }
        if (contactInfo.phone && !phoneValid(contactInfo.phone)) {
            this.setState({ phoneValid: false });
            fieldsValid = false;
        }
        if (!contactInfo.message) {
            this.setState({ messageValid: false });
            fieldsValid = false;
        }

        return fieldsValid;
    }

    renderSubmissionResponse = () => {

        const { response } = this.state;

        let responseMessage = '';
        response.ok ? responseMessage = 'Your note has been received. Thank you!' : responseMessage = 'Oops! Something went wrong, please try again later.';

        return <div className='resp-message header-5' style={!response.complete ? { display: 'none' } : {}}>{responseMessage}</div>
    }

    resetResponse = () => this.setState({ response: { complete: false, ok: false } });

    render() {

        const { response } = this.state;

        return (
            <div className="contactForm-container">
                <div className="contactForm-Note">
                    <div className="header-2 fw-boldest noteText">Send us a note</div>
                        {this.renderForm()}
                        {response.complete ? this.renderSubmissionResponse() : ''}
                    <div className="contact-plusSignSeparator"></div>
                </div>
            </div>
        );
    }
}

export default SendNoteForm;