import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';



class ServicesTypesSection extends React.Component<any, any> {
    scrollToTop = () => {
        window.scroll(0,0);
    }
    render() {
        return (
            <div className="services-types-container">
                <div className="services-content-container">
                    <div className="services-contentMain-container">
                        <div className="services-text-background-container">
                            <div className="services-text-content">
                                <div className="services-plusIconsUp"></div>
                                <div className="services-header-container" >
                                    <div className="services-brighter-header">Services</div>
                                    <div className="services-lighter-header">for all transaction </div>
                                    <div className="services-lighter-header">types</div>
                                </div>
                                <div className="services-plusIconsDown"></div>                            
                                                       
                                </div>
                            <div className="services-titleEscrow-containerMob">
                            <div className="services-titleEscrow-Text">
                                <div className="services-titleIns">Title Insurance</div>
                                    <p className="services-titleIns-Des">Currently licensed in 35 states, FIN Title offers Origination Title Insurance solutions for purchase and refinance transactions, as well as an End-to-End Default program for insured or non-insured Foreclosure, REO and Loss Mitigation products and services.</p>
                                <NavLink to="/services" className="services-paragraph-end-link" onClick={this.scrollToTop}>Learn More</NavLink>
                                <div className="services-titleEscrow-TextBottom">
                                    <div className="services-titleIns">Escrow and Closing</div>
                                    <p className="services-titleIns-Des">FIN Title offers Resale, Refinance and REO Closing services. Each service employs an experienced and seasoned Escrow Officer to manage potential challenges and state requirements.</p>
                                    <NavLink to="/services" className="services-paragraph-end-link" onClick={this.scrollToTop}>Learn More</NavLink>
                                </div>
                            </div>

                        </div>
                        </div>
                            <div className="services-titleEscrow-containerWeb">
                                <div className="services-titleEscrow-Text">
                                    <div className="services-titleIns">Title Insurance</div>
                                    <p className="services-titleIns-Des">Currently licensed in 35 states, FIN Title offers Origination Title Insurance solutions for purchase and refinance transactions, as well as an End-to-End Default program for insured or non-insured Foreclosure, REO and Loss Mitigation products and services.</p>
                                    <NavLink to="/services" className="services-paragraph-end-link" onClick={this.scrollToTop}>Learn More</NavLink>
                                    <div className="services-titleEscrow-TextBottom">
                                        <div className="services-titleIns">Escrow and Closing</div>
                                        <p className="services-titleIns-Des">FIN Title offers Resale, Refinance and REO Closing services. Each service employs an experienced and seasoned Escrow Officer to manage potential challenges and state requirements.</p>
                                        <NavLink to="/services" className="services-paragraph-end-link" onClick={this.scrollToTop}>Learn More</NavLink>
                                    </div>
                                </div>

                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ServicesTypesSection;