import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
    elementVisible: boolean;
}

class WhyUsSection extends React.Component<any, State> {
    state = {
        elementVisible: false
    }

    handleVisibilityUpdate = (e: any, data: any) => {
        const { elementVisible } = this.state;
        const { topVisible, bottomVisible } = data.calculations;

        if (!elementVisible && (topVisible || bottomVisible)) {
            this.setState({
                elementVisible: true,
            })
        }
    }
    render() {
        const { elementVisible } = this.state;

        return (
            <div>
            <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="whyWork-section">
                <div className="whyWork-section-mainContainer">
                    <div className="whyWork-sectionOver">
                        <div className="whyWork-text-background-container">
                            <div className="whyWork-text-contentLeft bottomSecLeft">
                                <div className="whyWork-lighter-header">Why work with us?</div>
                                <div className="paragraph fw-light txt-inner">We live in a digital era – using mobile devices to create, cloud computing to collaborate, cognitive computing and artificial intelligence to improve operations, data analysis to extract key insights and make complex decisions, and yet the title insurance industry has yet to take advantage of these technologies in order to create a simplified real estate closing experience.</div>
                               <div className="header-5 fw-bold about-finChange">Well, FIN is here to change all that.</div>
                            </div>
                        </div>
                        <div className="whyWork-text-background-container">
                            <div className="whyWork-text-contentRight imgSec">
                                <Transition visible={elementVisible} animation="fade up" duration={2500}>
                                    <div className="whyWork-ImgContainer">
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </div>
                
            </Visibility>
            <div className="whyWork-bottomTextSec">
                <div className="header-5 fw-bold txtChange">What’s driving this change?</div>
                <div className="paragraph fw-light txt-innerP">The diverse team of professionals at FIN include title insurance, real estate industry and technology experts with a complimentary mix of backgrounds in operations, compliance, security, big data, analytics, cloud computing, platform development, customer relations and more - all with the singular goal of transforming how title insurance is processed and real estate closing services are provided.</div>
            </div>
            </div>
        );
    }
}

export default WhyUsSection;