import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import NavBar from './components/navBar';
import Footer from './components/footer';
import 'semantic-ui-css/semantic.min.css'

import AboutPageContainer from './components/aboutPage/aboutPageContainer';
import { CookieBanner } from './components/cookieBanner';
import ContactPageContainer from './components/contactPage/contactPageContainer'; 
import HomePageContainer from './components/homePage/homePageContainer';
import ServicesPageContainer from './components/servicesPage/servicesPageContainer';
import TechPageContainer from './components/techPage/techPageContainer';
import PrivacyPageContainer from './components/privacyTerms/privacyPage';
import TermsPageContainer from './components/privacyTerms/termsConditionPage';
import StyleGuide from './styleGuide';


interface State {
    hiringLinkClicked: boolean;
}

class App extends Component<any, State> {

    state = {
        hiringLinkClicked: false
    }

    handleHiringLinkClick = () => this.setState({ hiringLinkClicked: true });
    resetHiringLink = () => this.setState({ hiringLinkClicked: false });

	render() {
		return (							
			<div>
                <Router>
                    <div>
                        {/* <NavBar /> */}
                        <Switch>
                            <Route path='/about' render={() => <AboutPageContainer handleHiringLinkClick={this.handleHiringLinkClick}/>} />
                            <Route path='/contact' render={() => <ContactPageContainer hiringLinkClicked={this.state.hiringLinkClicked} resetHiringLink={this.resetHiringLink}/>} />
                            <Route path='/services' render={() => <ServicesPageContainer handleHiringLinkClick={this.handleHiringLinkClick}/>} />
                            <Route path='/technology' component={TechPageContainer} />
                            <Route path='/style-guide' component={StyleGuide}/>
                            <Route path='/terms' component={TermsPageContainer} />
                            <Route path='/privacy' component={PrivacyPageContainer} />
                            <Route path='/' component={HomePageContainer} /> 
                        </Switch>
                        {/* <Footer /> */}
                        <CookieBanner />
                    </div>
                </Router>
			</div>										
		);
	}
}

export default App;
