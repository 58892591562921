import * as React from 'react';
import HeroSection from './heroSection';
import Footer from '../footer';
import { NavLink } from 'react-router-dom';

interface Props {
    handleHiringLinkClick: () => void;
}

class ServicesPageContainer extends React.Component<Props, any> {
    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="service-mainContainer">
                <HeroSection />
                <div className='map-section'>
                    <div className='map-img' />
                </div>
                <div className="aboutHiring-container">
                    <div className="aboutHiring-content">
                        <div className="aboutHiring-Title">We’re Hiring</div>
                        <div className="aboutHiring-desc">We’re always looking for qualified and passionate individuals to join our team.  We’d love to talk to you.</div>
                        <div className="aboutHiring-ContactSec">
                            <a href="tel:1-888-346-8485" className="btn-xlarge btn-white">Call (888) FIN-Title</a>
                            <span className="aboutHiring-seperator">or</span>
                            <NavLink to="/contact" onClick={this.scrollToTop} className="aboutHiring-Sec">
                                <button className="btn-xlarge btn-pink contact" onClick={this.props.handleHiringLinkClick}>
                                    <div className="btn-Contact">Contact Us</div>
                                    <span className="icon_right"></span>
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default ServicesPageContainer;