import * as React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { List } from 'semantic-ui-react'
import { titlefySignInUrl } from '../../utilities/titlefySignInUrl';

interface State {
    autoPlayOn: boolean;
}

class PlatformSection extends React.Component<any, State> {
    state = {
        autoPlayOn: true
    }

    handleFocus = () => {
        this.setState({ autoPlayOn: false })
    }


    render() {

        const { autoPlayOn } = this.state;
        const sliderSettings = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 750,
            autoplay: autoPlayOn,
            autoplaySpeed: 3000,
            pauseOnHover: false
        };

        return (
            <div className="platform-section">
                <div className="platform-section-mainContainer">
                    <div className="platform-sectionOver">
                        <div className="platform-text">
                            <div className="platform-carousel">
                                <div className="platform-carouselRight">
                                    <div className="slider-container" onFocus={this.handleFocus}>
                                        <Slider {...sliderSettings}>
                                            <div className="img slide-1" />
                                            <div className="img slide-2" />
                                            <div className="img slide-3" />
                                            <div className="img slide-4" />
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className="platform-text-left">
                                <div className="platform-text-leftSec">
                                    <div className="platform-heading">The FIN Platform</div>
                                    <div className="platform-desc">Powered by Flueid Pro technology, our FIN Platform is a single integrated platform for all your title and closing needs.</div>
                                    <div className="platform-descInner">
                                        <div className="platform-subHeading">Property and Ownership Research</div>
                                        <div className="platform-descSubMain">
                                            <div className="platform-desc-subHead">
                                                <List as='ul'>
                                                    <List.Item as='li' className="platform-description nation">Nationwide property search</List.Item>
                                                    <List.Item as='li' className="platform-description">Ownership and transaction history </List.Item>
                                                    <List.Item as='li' className="platform-description">Turnover potential (Hot Property) rating</List.Item>
                                                    <List.Item as='li' className="platform-description">Geographic property farming</List.Item>
                                                </List>
                                            </div>
                                            <div className="platform-desc-subHead">
                                                <List as='ul'>
                                                    <List.Item as='li' className="platform-description nation">Property valuation and equity</List.Item>
                                                    <List.Item as='li' className="platform-description">Custom Buyer’s Closing Estimate</List.Item>
                                                    <List.Item as='li' className="platform-description">Custom Seller’s Net Sheet</List.Item>
                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="platform-descInner">
                                        <div className="platform-subHeading">More Features Coming Soon</div>
                                        <div className="platform-descSubMain">
                                            <div className="platform-desc-subHead">
                                                <List as='ul'>
                                                    <List.Item as='li' className="platform-description nation">Ordering and fulfillment automation</List.Item>
                                                    <List.Item as='li' className="platform-description">Fee quotes</List.Item>
                                                </List>
                                            </div>
                                            <div className="platform-desc-subHead">
                                                <List as='ul'>
                                                    <List.Item as='li' className="platform-description nation">Closing scheduling</List.Item>
                                                    <List.Item as='li' className="platform-description">Closing signing</List.Item>
                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="platform-downloadSignInSection">
                                        <a href="https://apps.apple.com/us/app/flueid-pro/id1463006287" target="_blank" className="platform-iconDownload"></a>
                                        <div className="platform-seperator">or</div>
                                        <div className="WorkTogether-Sec">
                                            <button className="btn-xxlarge btn-pink contact">
                                                <div className="btn-Contact"><a href={titlefySignInUrl()} style={{ color: "#FFFFFF" }} >Sign Into Desktop</a></div>
                                                <span className="icon_right"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default PlatformSection;