import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';

interface State {
    value1Visible: boolean;
    value2Visible: boolean;
    value3Visible: boolean;
}
class ValuesSection extends React.Component<any, State> {
    state = {
        value1Visible: false,
        value2Visible: false,
        value3Visible: false,
    }
    handleUpdate = (e: any, data: any, valueName: string) => {
        switch (valueName) {
            case "value1Visible":
                if (!this.state.value1Visible && data.calculations.topVisible) {
                    this.setState({ value1Visible: true })
                    return "";
                }
                return "";
            case "value2Visible":
                if (!this.state.value2Visible && data.calculations.topVisible) {
                    this.setState({ value2Visible: true })
                    return "";
                }
                return "";
            case "value3Visible":
                if (!this.state.value3Visible && data.calculations.topVisible) {
                    this.setState({ value3Visible: true })
                    return "";
                }
                return "";
            default:
                return {}
        }
    }
    render() {
        const { value1Visible, value2Visible, value3Visible } = this.state;

        return (
            <div className="value-serviceContainer">
                <div className="value-experience-container">
                    <div className="value-content-container">
                        <div className="value-transition-container">
                            <div className="value-container-column-left">
                            </div>
                        </div>
                        <div className="value-text-background-container">
                            <div className="value-text-content">
                                <div className="value-brighter-header">What we value</div>
                                <Visibility fireOnMount onUpdate={(e, data, valueName = "value1Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
                                    <Transition visible={value1Visible} animation="fade up" duration={2500}>
                                        <div className="paragraph paragraphText">
                                            <div className="header-5 fw-bold semiText">Culture</div>
                                            FIN Title exists to help our clients succeed in the competitive real estate industry. Our deep commitment to provide excellent service flows through our people and technology.
                                </div>
                                    </Transition>
                                </Visibility>
                                <Visibility fireOnMount onUpdate={(e, data, valueName = "value1Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
                                    <Transition visible={value1Visible} animation="fade up" duration={2500}>
                                        <div className="paragraph paragraphText innovation">
                                            <div className="header-5 fw-bold semiText">Innovation</div>
                                                Innovation is at the crux of everything we do. By embracing modern title solutions, FIN Title increases the timeliness, accuracy and security of the title and settlement services we provide.
                                            </div>
                                    </Transition>
                                </Visibility>
                                <Visibility fireOnMount onUpdate={(e, data, valueName = "value1Visible") => this.handleUpdate(e, data, valueName)} className="visibility-value-container">
                                    <Transition visible={value1Visible} animation="fade up" duration={2500}>
                                        <div className="paragraph paragraphText service">
                                            <div className="header-5 fw-bold semiText">Service</div>
                                                Consistent and expert service is what we strive for every day, on every transaction. Our goal is to make the closing process simple and guide our clients through it every step of the way.
                                            </div>
                                    </Transition>
                                </Visibility>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ValuesSection;