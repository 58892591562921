import * as React from 'react';
import { Office, headquarters, dallas, coraopolis } from '../../data/offices';

interface Props {
    activeOffice: Office;
}

interface State {
    googleMapScriptLoaded: boolean;
}

class MapContainer extends React.Component<Props, State> {    

    state = {
        googleMapScriptLoaded: false
    }

    private googleMapRef = React.createRef<HTMLDivElement>();
    map: any;

    componentDidMount() {

        // add the google script
        const googleMapScriptExists: any = document.getElementById('google-map-script');

        if (!googleMapScriptExists) {

            // create the google maps script and add it to the document
            const googleMapScript = document.createElement('script');
            googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCJe2dtKmom-Ng8XcXg4Ihvff2QpSp3c6Q';
            googleMapScript.id = 'google-map-script';
            window.document.body.appendChild(googleMapScript);

            // ensure the google map script has loaded before initializing map and using google maps API
            googleMapScript.addEventListener('load', () => {
                this.setState({ googleMapScriptLoaded: true });
            });

        } else {
            this.setState({ googleMapScriptLoaded: true });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!prevState.googleMapScriptLoaded && this.state.googleMapScriptLoaded) {
            this.initMap();
        }

        if (prevProps.activeOffice !== this.props.activeOffice) {
            if (this.map) {
                this.map.setCenter({ lat: this.props.activeOffice.position.lat, lng: this.props.activeOffice.position.lng });
            }
        }
    }

    initMap() {
        const { activeOffice } = this.props;
        const googleWindow: any = window;
        const googleAPI = googleWindow.google;
        
        const mapRef = this.googleMapRef.current;

        if (mapRef) {

            this.map = new googleAPI.maps.Map(mapRef, {
                zoom: 14,
                center: new googleAPI.maps.LatLng(activeOffice.position.lat, activeOffice.position.lng),
                mapTypeId: 'roadmap',
                styles: [
                    {
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            { visibility: "off" }
                        ]
                    }
                ],
                gestureHandling: 'greedy'
            });

            const locationIcon = {
                url: "icon-map-pin@2x.png",
                scaledSize: new googleAPI.maps.Size(60, 60)
            };    

            new googleAPI.maps.Marker({
                position: new googleAPI.maps.LatLng(headquarters.position.lat, headquarters.position.lng),
                icon: locationIcon,
                map: this.map
            });

            new googleAPI.maps.Marker({
                position: new googleAPI.maps.LatLng(dallas.position.lat, dallas.position.lng),
                icon: locationIcon,
                map: this.map
            });

            new googleAPI.maps.Marker({
                position: new googleAPI.maps.LatLng(coraopolis.position.lat, coraopolis.position.lng),
                icon: locationIcon,
                map: this.map
            });

        }
    }

    render() {
        return (
            <div
                className='map-container'
                id='google-map-irvine'
                ref={this.googleMapRef}
            />
        );
    }
}

export default MapContainer;