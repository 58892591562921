import * as React from 'react';
import { Header, Modal, Transition } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { titlefySignInUrl } from '../utilities/titlefySignInUrl';

interface State {
	previousScrollY: number,
	scrollingUp: boolean;
	mobileMenuOpen: boolean;
	calcModalOpen: boolean;
}

class NavBar extends React.Component<any, State> {

	state: State = {
		previousScrollY: 0,
		scrollingUp: false,
		mobileMenuOpen: false,
		calcModalOpen: false
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);
		this.checkUrl();

	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	checkUrl = () => {
		var pathName = window.location.pathname.split('/');
		var newPath = pathName[1];
		return newPath;
	}

	handleScroll = () => {
		const { previousScrollY } = this.state;

		if (window.scrollY < previousScrollY) {
			this.setState({
				previousScrollY: window.scrollY,
				scrollingUp: true
			});
		} else {
			this.setState({
				previousScrollY: window.scrollY,
				scrollingUp: false
			});
		}
	}

	openMobileMenu = () => {
		this.setState({ mobileMenuOpen: true });	
    }

	closeMobileMenu = () => {
		this.setState({ mobileMenuOpen: false });
	}

	scrollToTop = () => { 
		window.scrollTo(0, 0); 
    } 

	renderMobileMenu = () => {
		return (
			<React.Fragment>
			    <Transition visible={this.state.mobileMenuOpen} animation="fade" duration={500}>
				    <div className="navbar-mobile-menu-open">
					    <div className="navbar-homeIcon">
						    <a href="/" className="navBarIcon navbar-logo-section">
							    <NavLink to="/" className="navbar-logo-section" onClick={this.scrollToTop}>
							    </NavLink>
						    </a>
						    <div className="close-mobile-menu-icon" onClick={this.closeMobileMenu}>&times;</div>
					    </div>
					    <div className="navbar-mobile-menu-item-container">
						    <div className="navbar-menu-item menu-item-margin" >
                            </div>
							<div id='qet-quote-mob-link' className="navbar-menu-item menu-item-margin" onClick={() => this.setState({ calcModalOpen: !this.state.calcModalOpen })}>
                                Get A Quote
                            </div>
						    <div className="navbar-menu-item menu-item-margin" onClick={this.scrollToTop} >
							    <NavLink to="/services" >
								    Services
							    </NavLink>
						    </div>
						    <div className="navbar-menu-item menu-item-margin" >
							    <NavLink to="/technology">
								    Technology
							    </NavLink>
						    </div>
						    <div className="navbar-menu-item menu-item-margin">
							    <NavLink to="/about">
								    About
							    </NavLink>
						    </div>
						    <div className="navbar-menu-item menu-item-margin" >
							    <NavLink to="/contact">
								    Contact
							    </NavLink>
						    </div>
						    <div className="navbar-menuSeparator menu-item-margin"></div>
						    {/* <div className="navbar-menu-item menu-item-margin" >
							    Sign Up
						    </div> */}
						    <a href={titlefySignInUrl()} className='menu-item-margin'>
							    <button className="btn-medium btn-pink">
								    Sign In
						        </button>
						    </a>
					    </div>
				    </div>
			    </Transition>
			</React.Fragment>
		);
    }

	render() {

		let navBarCutoffHeight;
		if (window.innerWidth < 992) {
			navBarCutoffHeight = 150;
		} else {
			navBarCutoffHeight = 300;
		}
        
		const { scrollingUp, previousScrollY, mobileMenuOpen, calcModalOpen } = this.state;		
		const scrollNavBarVisible = scrollingUp && previousScrollY > navBarCutoffHeight;
		
		return (
			<React.Fragment>
				<Modal
					className=""
					dimmer="inverted"
					open={calcModalOpen}					
					onClose={() => this.setState({ calcModalOpen: false })}>					
					<Header className="border-none" floated="right">
						<div className="close-mobile-menu-icon close-mobile-menu-icon-iframe-pad" onClick={() => this.setState({ calcModalOpen: false })}>×</div>
					</Header>										
					<Modal.Content>
						<iframe
							name="GFE_main" 
							src=" https://www.lodestarss.com/Live/FIN_Title/Login/index.php?guest=1"						
							className="iframe-calculator"
							width='100%'
							height='715'
							frameBorder='0'
							marginHeight={0}
							marginWidth={0}
						/>
					</Modal.Content>					
				</Modal>
				<div className="navbar-container">
					<div className="navbar-content">
						<Link to="/" className="navbar-logo-section" onClick={this.scrollToTop} ></Link>
                        <div className="navbar-menu-section">
                            {/*<a className='navbar-menu-services' target="_blank" href='https://quote.closepin.com/Quote?k=0F4098C7-7315-4C7C-B464-E1BB65118FD4'>*/}
                            {/*    Get A Quote*/}
							{/*</a>*/}
							<div className='navbar-menu-services' onClick={() => this.setState({calcModalOpen: !calcModalOpen})}>
								Get A Quote
							</div>
							<Link to="/services" className="navbar-menu-services" onClick={this.scrollToTop} >Services
							    <div className={`${this.checkUrl() === 'services' ? 'navBar-servicesHighlighter' : ''}`}></div>
							</Link>
							<Link to="/technology" className="navbar-menu-services" onClick={this.scrollToTop}>Technology
							    <div className={`${this.checkUrl() === 'technology' ? 'navBar-TechHighlighter' : ''}`}></div>
							</Link>
							<Link to="/about" className="navbar-menu-services" onClick={this.scrollToTop}>About
							    <div className={`${this.checkUrl() === 'about' ? 'navBar-servicesHighlighter' : ''}`}></div>
							</Link>
							<Link to="/contact" className="navbar-menu-services" onClick={this.scrollToTop}>Contact
							    <div className={`${this.checkUrl() === 'contact' ? 'navBar-servicesHighlighter' : ''}`}></div>
							</Link>
							    <div className="navbar-menu-separator"></div>
                            {/* <div className="navbar-menu-services">Sign Up</div> */}
                            <a href={titlefySignInUrl()} style={{ color: "#FFFFFF" }}>
                                <button className="btn-medium btn-pink" >
                                    Sign In
                                </button>
                            </a>
						</div>
						{
							!mobileMenuOpen &&
							<div className="navbar-hamburger" onClick={() => this.openMobileMenu()}>
								<div className="hamburger-bar-homepage"></div>
								<div className="hamburger-bar-homepage"></div>
							</div>
						}
					</div>
					{ this.renderMobileMenu() }
				</div>
                <Transition visible={scrollNavBarVisible} animation="fade" duration={500}>
                    <div>
                        <div className="navbar-container-scrolling" id="scroll">
						    <div className="navbar-content-scrolling">
							    <Link to="/" className="navbar-logo-section" onClick={this.scrollToTop} />
                                <div className="navbar-menu-section">
									<div className='navbar-menu-services' onClick={() => this.setState({ calcModalOpen: !calcModalOpen })}>
                                        Get A Quote
                                    </div>
								    <Link to="/services" className="navbar-menu-services" onClick={this.scrollToTop}>Services</Link>
								    <Link to="/technology" className="navbar-menu-services" onClick={this.scrollToTop}>Technology</Link>
								    <Link to="/about" className="navbar-menu-services" onClick={this.scrollToTop}>About</Link>
								    <Link to="/contact" className="navbar-menu-services" onClick={this.scrollToTop}>Contact</Link>
								    <div className="navbar-menu-separator"></div>
								    {/* <div className="navbar-menu-services">Sign Up</div> */}
                                    <a href={titlefySignInUrl()} style={{ color: "#FFFFFF" }}>
                                        <button className="btn-medium btn-pink">
                                            Sign In
                                        </button>
                                    </a>
                                </div>
                                {
                                    !mobileMenuOpen &&
                                    <div className="navbar-hamburger" onClick={() => this.openMobileMenu()}>
                                        <div className="hamburger-bar-homepage"></div>
                                        <div className="hamburger-bar-homepage"></div>
                                    </div>
                                }
                                
						    </div>
					    </div>
                    </div>
				</Transition>
			</React.Fragment>
		);
	}
}

export default NavBar;
