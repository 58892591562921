import * as React from 'react';
import { Visibility, Transition } from 'semantic-ui-react';
import { Menu, Segment } from 'semantic-ui-react';

interface State {
    elementVisible: boolean;
    activeItem: string
}

class ServicesDescriptionSection extends React.Component<any, State> {
    state = {
        elementVisible: false,
        activeItem: 'default services'
    }

    handleVisibilityUpdate = (e: any, data: any) => {
        const { elementVisible } = this.state;
        const { topVisible, bottomVisible } = data.calculations;

        if (!elementVisible && (topVisible || bottomVisible)) {
            this.setState({
                elementVisible: true,
            })
        }
    }

    handleItemClick = (e: any, { name }: any) => {
        this.setState({ activeItem: name })
    }

    render() {
        const { elementVisible, activeItem } = this.state;

        return (
            <div>
                {this.props.value === '' &&
                    <Visibility fireOnMount onUpdate={this.handleVisibilityUpdate} className="serviceDes-section">
                        <div className="serviceDes-section-mainContainer">
                            <div className="serviceDes-sectionOver">
                                <div className="serviceDes-text-background-containerLeft">
                                    <div className="serviceDes-text-contentLeft bottomSecLeft">
                                        <div className="serviceDesLeft">
                                            <div className="header-3 fw-extra-bold header">Services for all transaction types</div>
                                            <div className="paragraph fw-light txt-inner">We are dedicated to providing our customers with an unparalleled combination of professional expertise and outstanding customer service. Our Title and Escrow teams offer decades of experience in real estate closing procedures and the components of title insurance, taxes and deeds. You can rest assured that their superior technical experience and organizational know-how coupled with our FIN Platform will result in efficient and accurate closings.</div>
                                            <div className="serviceDes-plusIcon"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="serviceDes-text-background-containerRight">
                                    <div className="serviceDes-text-contentRight imgSec">
                                        <div className="serviceDes-plusIconTop"></div>
                                        <Transition visible={elementVisible} animation="fade right" duration={2500}>
                                            <div className="serviceDes-ImgContainer">
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Visibility>
                }

                <Menu pointing secondary>
                    <Menu.Item
                        name='default services'
                        active={activeItem === 'default services'}
                        onClick={this.handleItemClick}
                    />
                    <Menu.Item
                        name='origination services'
                        active={activeItem === 'origination services'}
                        onClick={this.handleItemClick}
                    />
                </Menu>
                {this.state.activeItem === 'default services' &&
                    <div className="serviceDes-defaultContainer">
                        <div className="serviceDesc-section">
                            <div className="header-3 fw-extra-bold header"> Default Services</div>
                            <div className="paragraph fw-light paragraph">At FIN, we realize that Default and REO transactions include a unique set of challenges.  Our industry-best experts, supported by FIN’s technology, specialize in overcoming those challenges.  Whether you are a national bank, mortgage servicer, asset manager, auction house, foreclosure attorney, realtor, investor or any other stakeholder in the process, our experts stand ready to exceed your expectations.</div>
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Foreclosure Title</div>
                                <div className="paragraph fw-light para">Offering single-point-of-contact service, FIN is able to manage the entire foreclosure portfolios with our nationwide attorney network and direct operations in Irvine, CA. Our National Foreclosure Title Services division provides all foreclosure title products from Trustee’s Sale Guarantees and Litigation Guarantees to Uninsured Property Reports</div>
                                <div className="foreClosure-title">Foreclosure Title Services</div>
                                <div className="foreClosureServices">
                                    <div className="foreClosureText">
                                        <div className="foreClosureTxt">Trustee Sale Guarantee</div>
                                        <div className="foreClosureTxt guarantee">Foreclosure Information Report</div>
                                        <div className="foreClosureTxt guarantee">Non-Judicial Foreclosure Report</div>
                                        <div className="foreClosureTxt guarantee">Litigation Guarantee</div>
                                        <div className="foreClosureTxt guarantee">Preliminary Judicial Report</div>
                                    </div>
                                   
                                </div>
                            </div>

                            <div className="">
                                <div className="header-5 fw-bold semiHeader">REO Title and Closing</div>
                                <div className="paragraph fw-light para">FIN is your one stop for all REO curative, title insurance and closing services. Our experienced title resolution team can cure even the most complex issues, and is backed by a strong network of local attorneys and the power of multiple national underwriters. Our team expertly handles the REO Closing or Offers Closing management services to oversee the entire closing process from contract to final proceeds.</div>
                            </div>
                            
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Loss Mitigation</div>
                            <div className="paragraph fw-light para">FIN provides a full spectrum of loss mitigation services from Deed In Lieu title services, Short-Sale title services, and Loan Modification Endorsements. In addition to mitigating risk, our processes have proven time and again to reduce cycle time and operating costs for lenders.</div>
                                <div className="foreClosureServices">
                                    <div className="foreClosureText closing">
                                        <div className='foreClosureTxt guarantee'>Property Reports</div>
                                        <div className='foreClosureTxt guarantee'>MMP or ALTA Policy</div>
                                        <div className='foreClosureTxt guarantee'>Document Generation</div>
                                        <div className='foreClosureTxt guarantee'>Signing Services</div>
                                        <div className='foreClosureTxt guarantee'>Recording Services</div>
                                    </div>
                                </div>
                            </div>
                            <div className="serviceIconPlus">
                                <div className="serviceDes-plusIconTop"></div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.activeItem === 'origination services' &&
                    <div className="serviceDes-defaultContainer">
                        <div className="serviceDesc-section">
                            <div className="header-3 fw-extra-bold header"> Origination Services</div>
                            <div className="paragraph fw-light paragraph">With FIN’s automated underwriting, we pinpoint curative issues quickly or issue a commitment instantly. From mortgage brokers to banks and credit unions, we provide our clients with a personalized title and closing process. With our tech-forward approach, artificial intelligence (AI) handles traditionally manual and time-consuming operations, streamlining the time it takes to close.</div>
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Purchase Transactions</div>
                            <div className="paragraph fw-light para">FIN’s settlement experts support you and your clients with personalized service to meet any of your requirements. Our technology makes it easy to communicate and track your transactions while our modern platform offers an array of tools to help you save time.</div>                                                                
                            </div>
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Refinance Transactions</div>
                                <div className="paragraph fw-light para">Count on us for simple and transparent refinance transactions. From title research to disbursing funds experience the ease of local and exceptional service for you and your clients.</div>                                
                            </div>
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Home Equity</div>
                            <div className="paragraph fw-light para">
                                In today’s competitive lending environment, home equity originators need a title company that will streamline the application time and closing process while providing expert support. FIN’s offers full home equity solutions for a single source for title, valuation, settlement and post-close support.
                                
                                </div>                                
                            </div>
                            <div className="serviceIconPlus">
                                <div className="serviceDes-plusIconTop"></div>
                            </div>
                        </div>
                    </div>
                }
                {this.props.value === 'Retail Services' &&
                    <div className="serviceDes-defaultContainer">
                        <div className="serviceDesc-section">
                            <div className="header-3 fw-extra-bold header"> Retail Services</div>
                            <div className="paragraph fw-light paragraph">A title company that executes closing transactions consistently is essential to building good and lasting relationships with homeowners. FIN’s settlement experts support you and your clients with personalized service to meet any of your requirements. Our technology makes it easy to communicate and track your transactions while our modern platform offers an array of tools to help you save time and grow your business.</div>
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Title Insurance</div>
                                <div className="paragraph fw-light para">Our Title and Settlement services include preliminary title reports, title insurance, quick commitments and order tracking provided by experts capable of handling any transactions. </div>
                                <div className="foreClosure-title">Title Services</div>
                                <div className="foreClosureServices">
                                    <div className="foreClosureText closing">
                                        <div className="foreClosureTxt">Purchase</div>
                                        <div className="foreClosureTxt guarantee">Refinance</div>
                                        <div className="foreClosureTxt guarantee">Signing Services</div>
                                    </div>
                                    <div className="foreClosureText closing">
                                        <div className="foreClosureTxt">Recording Services</div>
                                        <div className="foreClosureTxt guarantee">Post-Closing Services</div>
                                    </div>
                                    <div className="foreClosureButton closingBtn">
                                        <button className="btn-xlarge btn-white">Call (888) FIN-Title</button>
                                        <div className="aboutHiring-Sec Reo">
                                            <button className="btn-xlarge btn-pink contact">
                                                <div className="btn-Contact">Contact Us</div>
                                                <span className="icon_right"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="plusIcon"></div>
                            </div>
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Escrow and Closing</div>
                                <div className="paragraph fw-light para">Our advanced technical solutions create a digital environment that increases our efficenty. This means processors, underwriters, and closers can access real-time status on every real estate transaction. This digital efficency allows us to minimize process defects, which reduce costs and increase overall customer satisfaction.</div>
                                <div className="foreClosure-title">Settlement Services</div>
                                <div className="foreClosureServices">
                                    <div className="foreClosureText">
                                        <div className="foreClosureTxt">Service 1</div>
                                        <div className="foreClosureTxt guarantee">Service 2</div>
                                        <div className="foreClosureTxt guarantee">Service 3</div>
                                    </div>
                                    <div className="foreClosureButton">
                                        <button className="btn-xlarge btn-white">Call (888) FIN-Title</button>
                                        <div className="aboutHiring-Sec">
                                            <button className="btn-xlarge btn-pink contact">
                                                <div className="btn-Contact">Contact Us</div>
                                                <span className="icon_right"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="plusIcon"></div>
                            </div>
                            <div className="">
                                <div className="header-5 fw-bold semiHeader">Robust Productivity Tech</div>
                                <div className="paragraph fw-light para">Building relationships and adding value is the key to conneting with agents and brokers. When you work with FIN you have access to the a wide array of modern technological solutions that will differentiate you from the competition. </div>
                                <div className="foreClosure-title">Modern Digital Tools</div>
                                <div className="foreClosureServices">
                                    <div className="foreClosureText closing">
                                        <div className="foreClosureTxt">Instant propety calculators</div>
                                        <div className="foreClosureTxt guarantee">On demand property reports</div>
                                        <div className="foreClosureTxt guarantee">Property farming </div>
                                    </div>
                                    <div className="foreClosureText closing">
                                        <div className="foreClosureTxt">Real time order status</div>
                                        <div className="foreClosureTxt guarantee">On-the-go open order</div>
                                    </div>
                                    <div className="foreClosureButton closingBtn">
                                        <button className="btn-xlarge btn-white">Call (888) FIN-Title</button>
                                        <div className="aboutHiring-Sec Reo">
                                            <button className="btn-xlarge btn-pink contact">
                                                <div className="btn-Contact">Contact Us</div>
                                                <span className="icon_right"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="serviceIconPlus">
                                <div className="serviceDes-plusIconTop"></div>
                            </div>
                        </div>
                    </div>
                }
                {this.props.value === 'Financial Pro' &&
                    <div className="serviceDes-defaultContainer">
                        <div className="serviceDesc-section">
                            <div className="header-3 fw-extra-bold header"> Services for Lenders, Mortgage Brokers, Credit Unions, and More</div>
                            <div className="paragraph fw-light paraFinance">Delayed or chaotic closings create distrust and anxiety for borrowers. With FIN’s automated underwriting, we pinpoint curative issues quickly or issue a commitment instantly. From mortgage brokers to banks and credit unions, we provide our clients with a personalized title and closing process. With our tech-forward approach, artificial intelligence (AI) handles traditionally manual and time-consuming operations, streamlining the time it takes to close.</div>
                            <div className="paragraph fw-light Dark">We look forward to working with you.</div>
                            <div className="plusIconFinance"></div>
                            <div className="financialImage" ></div>
                            <div className="serviceIconPlus">
                                <div className="serviceDes-plusIconTop"></div>
                            </div>
                        </div>
                    </div>
                }
                {this.props.value === 'Real Estate Pro' &&
                    <div className="serviceDes-defaultContainer">
                        <div className="serviceDesc-section">
                            <div className="header-3 fw-extra-bold header">Services for Agents, Brokers and Attorneys</div>
                            <div className="paragraph fw-light paraFinance">A title company that executes closing transactions consistently is essential to building good and lasting relationships with homeowners. FIN’s settlement experts support you and your clients with personalized service to meet any of your requirements. Our technology makes it easy to communicate and track your transactions while our modern platform offers an array of tools to help you save time and grow your business.</div>
                            <div className="paragraph fw-light Dark">We look forward to working with you.</div>
                            <div className="plusIconFinance"></div>
                            <div className="financialImage realStateImage" ></div>
                            <div className="serviceIconPlus">
                                <div className="serviceDes-plusIconTop"></div>
                            </div>
                        </div>
                    </div>
                }
                {this.props.value === 'Servicer' &&
                    <div className="serviceDes-defaultContainer">
                        <div className="serviceDesc-section">
                            <div className="header-3 fw-extra-bold header">Title and Escrow for Servicers and Investors</div>
                            <div className="paragraph fw-light paraFinance">With FIN’s simple open order and expert settlement team, expect increased accuracy and speed on all your orders. Powered by machine learning systems and lead by experienced mortgage experts, our people focus on delivering quality and speed. We offer loan maintenance solutions for mortgage servicers to keep documents and records up to date, monitor changes, and provide added customer value.</div>
                            <div className="paragraph fw-light Dark">We look forward to working with you.</div>
                            <div className="plusIconFinance"></div>
                            <div className="financialImage servicerImage" ></div>
                            <div className="serviceIconPlus">
                                <div className="serviceDes-plusIconTop"></div>
                            </div>
                        </div>
                    </div>
                }
                {this.props.value === 'Consumer' &&
                    <div className="serviceDes-defaultContainer">
                        <div className="serviceDesc-section">
                            <div className="header-3 fw-extra-bold header">Services for Homeowners, Buyers and Sellers</div>
                            <div className="paragraph fw-light paraFinance">Taking the step into homeownership is one of the most important decisions you will make in your lifetime. Choosing the right title insurance agency can make this step as easy as possible. When you look for a title agency, you want a company you can trust to handle your largest investment. Whether you are just starting the process or have already chosen your dream home, FIN Title has title and settlement experts on standby ready to answer any questions you have to help you through the entire home buying and selling experience. </div>
                            <div className="paragraph fw-light Dark">We look forward to working with you.</div>
                            <div className="plusIconFinance"></div>
                            <div className="financialImage consumerImage" ></div>
                            <div className="serviceIconPlus">
                                <div className="serviceDes-plusIconTop"></div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default ServicesDescriptionSection;