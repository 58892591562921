import * as React from 'react';
import HeroSection from './heroSection';
import MissionStatementSection from './missionStatementSection';
import HighTouchServiceSection from './highTouchServiceSection';
import ModernTechSection from './modernTechSection';
import CustomerExperienceSection from './customerExperienceSection';
import ServicesTypeSections from './servicesTypesSection';
import CustomerWeServeSection from './customersWeServeSection';
import Footer from '../footer';
import { NavLink } from 'react-router-dom';



class HomePageContainer extends React.Component<any, any> {

    scrollToTop = () => {
        window.scroll(0,0);
    }

    render() {
        return (
            <div className="homepage-container">
                <HeroSection />
                <MissionStatementSection />
                <HighTouchServiceSection />
                <ModernTechSection />
                <CustomerExperienceSection />
                <ServicesTypeSections />
                <CustomerWeServeSection />
                <div className="homePageWorkTogether-container">
                    <div className="WorkTogether-content">
                        <div className="WorkTogether-HeadPhoneImg"></div>
                        <div className="WorkTogether-Title">Let’s work together</div>
                        <div className="WorkTogether-desc">We’d love to talk to you about how we can help save you time and money.</div>
                        <div className="WorkTogether-ContactSec">
                            <a href="tel:1-888-346-8485" className="btn-xlarge btn-white" >Call (888) FIN-Title</a>
                            <span className="WorkTogether-seperator">or</span>
                            <NavLink to="/contact" className="WorkTogether-Sec" onClick={this.scrollToTop}>
                                <button className="btn-xlarge btn-pink contact">
                                    <div className="btn-Contact">Contact Us</div>
                                    <span className="icon_right"></span>
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default HomePageContainer;